<template>
  <v-form class="full-search" @submit.prevent="runSearch">

    <FreeSearch v-model="freeSearchParams"/>
    <spacer height="12px"/>
    <v-row>
      <v-col v-if="$vuetify.breakpoint.xsOnly">
        <v-btn color="primary darken-1" block @click="$emit('map')">
          <v-icon left class="icon-md">mdi-map</v-icon>
          {{ $t("search.map") }}
        </v-btn>
      </v-col>

      <v-col lg=3>
        <v-btn color="primary darken-1" block :class="['mobile-filter-btn', {active: filterOpen}]" @click="toggleFilters">
          <v-icon left class="icon-md">mdi-filter-variant</v-icon>
          {{ $t("search.filter") }}
        </v-btn>
      </v-col>

      <v-col cols=12 lg=9>
        <v-chip :small="$vuetify.breakpoint.xsOnly" color="accent" class="margin-5" v-for="(tag, i) in tags" :key="i">{{tag}}</v-chip>
      </v-col>
    </v-row>

    <div :class="['filters margin-tb-15', {open: filterOpen }]">
      <v-expansion-panels >
        <v-expansion-panel>

          <v-expansion-panel-header>
            <span class="font-18 font-600">{{ $t("estates.categories.title") }} ({{ typesFilter.length }})</span>

            <span class="clear-btn d-flex justify-end" v-if="typesFilter.length">
              <v-btn small icon @click.stop="typesFilter = []">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <Spacer width="5px"/>
            </span>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-divider/>
            <Types v-model="typesFilter" :key="typesFilter.length"/>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <Spacer height="20px"/>

      <v-row>
        <v-col>
          <v-row no-gutters>
            <v-col>
              <Size v-model="sizeRange"/>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6">
              <Rooms v-model="rooms"/>
            </v-col>
            <v-col cols="12" sm="6">
              <Price v-model="price" max="15000000"/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <Spacer height="20px"/>

      <v-expansion-panels >
        <v-expansion-panel>

          <v-expansion-panel-header>
            <span class="font-18 font-600">{{ $t("estates.props.title") }} ({{ featuresFilter.length }})</span>

            <span class="clear-btn d-flex justify-end" v-if="featuresFilter.length">
              <v-btn small icon @click.stop="featuresFilter = []">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <Spacer width="5px"/>
            </span>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-divider/>
            <Features v-model="featuresFilter" :key="featuresFilter.length"/>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <Spacer height="20px"/>

      <v-row justify="center" no-gutters>
        <v-btn color="secondary" type="submit" large min-width="200px" :block="$vuetify.breakpoint.mdAndDown">{{ $t("search.button_text") }}</v-btn>
      </v-row>
    </div>

  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'
import FreeSearch from './search-components/free-search'
import Price from './search-components/price-slider'
import Size from './search-components/size-slider'
import Rooms from './search-components/rooms-slider'
import Types from './search-components/types-search'
import Features from './search-components/features-search'

export default {
  name: 'FullSearch',

  components: {
    Price,
    Size,
    FreeSearch,
    Rooms,
    Types,
    Features
  },

  data () {
    return {
      initialSearch: true,
      filterOpen: false,
      addresses: [],
      price: this.$route.query.price || null,
      sizeRange: this.$route.query.size || null,
      rooms: this.$route.query.rooms || null,
      typesFilter: this.$route.query.types ? this.$route.query.types.split(',') : [],
      featuresFilter: this.$route.query.features ? this.$route.query.features.split(',') : [],
      freeSearchParams: {
        freeText: this.$route.query.search, // || `${this.$route.query.street || ''} ${this.$route.query.city || ''} ${this.$route.query.state || ''}`.trim() || '',
        dealType: this.$route.query.deal_type || 'sale'
      },
      filterButtunTracked: false,
      city: this.$route.query.city || null,
      street: this.$route.query.street || null,
      state: this.$route.query.state || null
    }
  },

  computed: {
    ...mapGetters(['locale']),

    tags () {
      let tags = [
        this.$t(`search.${this.freeSearchParams.dealType}`),
        this.price ? this.$options.filters.currency(this.price) : null,
        this.sizeRange ? `${this.sizeRange[0]} - ${this.sizeRange[1]} ${this.$t('search.size_units')}` : null,
        this.rooms ? `${this.rooms} ${this.$t('search.bedrooms')}` : null
      ]

      this.typesFilter.forEach((type) => {
        tags.push(this.$t(`estates.categories.${type}`))
      })

      this.featuresFilter.forEach((feature) => {
        tags.push(this.$t(`estates.props.${feature}`))
      })

      return tags.filter(Boolean)
    }
  },

  methods: {

    query () {
      let query = {}

      if (this.freeSearchParams.freeText) {
        query.freeText = this.freeSearchParams.freeText
      }

      if (this.price) {
        query.price = this.price
      }

      if (this.sizeRange) {
        query.size = {
          min: parseInt(this.sizeRange[0]) || 0,
          max: parseInt(this.sizeRange[1]) || 0
        }
      }

      if (this.rooms) {
        query.rooms = {
          min: parseInt(this.rooms),
          max: parseInt(this.rooms) + 1
        }
      }

      if (this.typesFilter.length) {
        query.types = this.typesFilter
      }

      if (this.featuresFilter.length) {
        query.features = this.featuresFilter.toString()
      }

      if (this.city && this.initialSearch) {
        const address = {}
        address.city = this.city

        if (this.street) {
          address.street = this.street
        }

        if (this.state) {
          address.state = this.state
        }

        if (Object.keys(address).length) {
          query.address = address
        }
      }

      query.dealType = this.freeSearchParams.dealType // deal_type alyaws has a value

      return query
    },

    runSearch () {
      const query = this.query()
      this.$emit('search', query)

      if (!this.initialSearch) {
        this.$mixpanel.track('full_search', {
          action: 'run_search',
          params: {
            price: this.price || false,
            deal_type: this.freeSearchParams.dealType,
            search_text: this.freeText || false,
            size_min: query.size ? query.size.min : false,
            size_max: query.size ? query.size.max : false,
            rooms: this.rooms || false,
            types: this.typesFilter,
            features: this.featuresFilter,
            query
          }
        })
      }
      this.initialSearch = false
    },

    toggleFilters () {
      this.filterOpen = !this.filterOpen
      if (!this.filterButtunTracked) {
        this.$mixpanel.track('full_search', { action: 'search_filter_opened' })
        this.filterButtunTracked = true
      }
    }
  },

  mounted () {
    this.runSearch()
  }
}
</script>

<style lang="scss" scoped>

.full-search {

  .mobile-filter-btn {

    .v-icon {
      transition: all .3s ease-out;
    }

    &.active {

      .v-icon {
        transform: rotate(180deg);
      }
    }
  }

  .filters {
    max-height: 0;
    overflow: hidden;
    transition: all .6s ease-out;

    &.open {
      max-height: 800px;

      @include xs-only {
        max-height: 1200px;
      }
    }
  }

  .clear-btn {
    height: 24px;
    margin-bottom: -4px;
    margin-top: -4px;

    .v-btn {
      margin-top: -2px;
    }
  }
}

</style>
