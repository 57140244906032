<template>
  <v-flex d-flex child-flex class="estates-list">
    <!-- <div class="header-image"/> -->

    <v-container fluid class="no-padding d-flex">

      <v-row no-gutters>

        <v-col sm="6" md=5>
          <div :class="['map-container fill-height', {'mobile-on': mobileMap}]">
            <v-btn v-if="mobileMap" color="secondary" @click="mobileMap = !mobileMap" class="map-close-btn">
              {{ $t("search.back_to_list") }}
            </v-btn>
            <Map ref="map"/>
          </div>
        </v-col>

        <v-col cols="12" sm="6" md=7>
          <div class="search-content">
            <div class="search-container">
              <Spacer height="40px"/>
              <FullSearch @search="getEstates" @map="mobileMap = true"/>
              <Spacer height="20px"/>
            </div>

            <v-row>
              <v-col cols=12 md="6" xl=4 v-for="(e, i) in estates" :key="i" class="estates-row">
                <EstateBox :estate="e"/>
              </v-col>

              <v-col v-if="!estates.length && !loading" class="text-center font-20">
                {{$t('estates.no_search_results')}}
                <spacer height="10px"/>
                <v-btn width="210px" large color="secondary" @click="openAlbi">{{$t('albi.button_text')}}</v-btn>
                <spacer height="10px"/>
                <wa-chat width="210px" dark icon-size="icon-xl">
                  <span class="font-15">&nbsp;{{$t('albi.wa_button_text')}}</span>
                </wa-chat>
              </v-col>
            </v-row>

            <v-row justify="center">
              <v-btn  v-if="showLoadMore"
                      color="secondary"
                      :loading="loading"
                      :block="$vuetify.breakpoint.xsOnly"
                      @click="loadMore"
                      v-intersect.quiet="loadMore">
                {{$t('common.load_more')}}
              </v-btn>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-flex>
</template>

<script type="text/javascript">
import EstatesService from '../estates-service'
import EstateBox from '@/app/modules/estates/components/estate-box'
import FullSearch from '@/app/modules/estates/components/search/full-search'
import Map from '@/common/components/map'
import WaChat from '@/app/common/components/wa-chatbot'

export default {
  name: 'Estates',

  metaInfo () {
    return {
      meta: [
        {
          property: 'title',
          content: this.$t('meta.estates.title', { estate_details: this.queryObject.free_text }),
          vmid: 'title'
        },
        {
          property: 'og:title',
          content: this.$t('meta.estates.title', { estate_details: this.queryObject.free_text }),
          vmid: 'og:title'
        },
        {
          property: 'og:description',
          content: this.$t('meta.estates.description', { estate_details: this.queryObject.free_text }),
          vmid: 'og:description'
        },
        {
          property: 'description',
          content: this.$t('meta.estates.description', { estate_details: this.queryObject.free_text }),
          vmid: 'description'
        }
      ]
    }
  },

  components: {
    EstateBox,
    FullSearch,
    WaChat,
    Map
  },

  data () {
    return {
      estates: [],
      pageNum: 1,
      queryObject: {},
      loading: true,
      loadSoldEstates: false,
      showLoadMore: false,
      mobileMap: false // ,
      // headerImages: ['israel', 'jerusalem', 'haifa-2', 'tel-aviv', 'tel-aviv-2'],
      // headerImage: null
    }
  },

  // computed: {
  // },

  methods: {
    // setHeaderImage () {
    //   const image = Math.floor(Math.random() * this.headerImages.length)
    //   return this.$cloudinary.url(`/app_images/${this.headerImages[image]}.webp`, { width: window.innerWidth, height: 80, crop: 'crop', opacity: 50 })
    // },

    pullLocationsData (estates) {
      const addresses = []
      const locations = estates.map((e) => {
        if (e.address.location) {
          return {
            cords: {
              lng: e.address.location.lon,
              lat: e.address.location.lat
            },
            address: e.address.general_address
          }
        }

        if (e.address.general_address) {
          addresses.push(e.address.general_address)
        }
        return null
      }).filter(Boolean)

      return { locations, addresses }
    },

    queryToObject (query) {
      const params = {
        free_text: query.freeText || ' ',
        category: query.types || null,
        deal_type: query.dealType || null,
        features: query.features || null,
        size: query.size || null,
        rooms: query.rooms || null,
        sector: null,
        price: {
          min: query.price - (query.price / 5) || 0,
          max: parseInt(query.price) + parseInt((query.price / 5)) || 999999999
        },
        address_search_dto: query.address || null
      }

      return Object.keys(params).reduce((acc, key) => {
        if (params[key]) {
          acc[key] = params[key]
        }
        return acc
      }, {})
    },

    async getEstates (query) {
      this.loading = true
      this.showLoadMore = false
      this.loadSoldEstates = false
      this.queryObject = this.queryToObject(query)
      this.queryObject.pagination = {
        page: 1,
        limit: 25
      }

      this.pageNum = 1
      this.estates = []
      const { results } = await EstatesService.searchEstates(this.queryObject)
      this.estates = results
      const locationsData = this.pullLocationsData(this.estates)
      if (this.$refs.map) {
        await this.$refs.map.clearMapMarkers()
        // TODO: improvement - duplicate markers rendering
        this.$refs.map.setMarkers(locationsData.locations)
        this.$refs.map.setMarkersByEstates(this.estates)
      }
      this.loading = false
      this.showLoadMore = !(this.estates.length < this.queryObject.pagination.limit - 1)

      if (!this.showLoadMore && !this.loadSoldEstates) {
        this.loadSoldEstates = true
        this.pageNum = 0
        this.loadMore()
      }
    },

    async loadMore () {
      if (this.loading) return
      this.loading = true
      this.queryObject.pagination = {
        page: ++this.pageNum,
        limit: 25
      }

      if (this.loadSoldEstates) {
        this.queryObject.status = 'sold'
      }

      const { results } = await EstatesService.searchEstates(this.queryObject)

      if (results.length) {
        this.estates = [...this.estates, ...results]
        const locationsData = this.pullLocationsData(results)
        this.$refs.map.setMarkers(locationsData.locations)
        this.$refs.map.setMarkersByEstates(this.estates)
      }

      this.loading = false
      if (results.length < this.queryObject.pagination.limit - 1) {
        if (!this.loadSoldEstates) {
          this.loadSoldEstates = true
          this.pageNum = 0
        } else {
          this.showLoadMore = false
        }
      }
    },

    openMap () {
      this.mobileMap = true
    },

    openAlbi () {
      this.$root.$emit('openAlbi', { refering_feature: 'search' })
    }
  },

  created () {
    // this.headerImage = this.setHeaderImage()
  }
}

</script>

<style lang="scss">

  .estates-list {

    // .header-image {
    //   position: relative;
    //   height: 70px;
    //   z-index: 2;
    //   box-shadow: 0 0px 20px 2px var(--v-secondary-darken1);
    // }

    .map-close-btn {
      position: absolute;
      bottom: 25px;
      right: 0;
      left: 0;
      max-width: 150px;
      margin: auto;
      z-index: 99;
    }

    .search-content {
      position: relative; // for the box-shadow
      overflow: auto;
      padding: 65px 20px 50px;
      background: linear-gradient(to bottom, var(--v-secondary-base) 0px, transparent 220px);

      @include md-and-up {
        padding: 55px 45px 75px;
      }

      @include sm-and-up {
        // height: calc(100vh - 266px);
        height: 100vh; // without footer
        // height: calc(100vh - 70px); // with header image
      }
    }

    .map-container {
      min-height: 400px;

      @include xs-only {
        position: fixed;
        top: 0;
        right: -110vw;
        width: 100vw;
        height: 100%;
        z-index: 9;
        transition: all .3s;

        &.mobile-on {
          right: 0;
        }
      }
    }
  }

</style>
